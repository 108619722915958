import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        signedIn: false,
        refreshToken: '',
    },
	reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            const {access_token, refresh_token} = action.payload
            state.token = access_token
            state.refreshToken = refresh_token
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.refreshToken = ''
        },
        setToken: (state, action) =>  {
            state.token = action.payload
        }
	},
})

export const { onSignInSuccess, onSignOutSuccess, setToken } = sessionSlice.actions

export default sessionSlice.reducer