import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn, refreshToken } = useSelector((state) => state.auth.session);

  const signIn = async ({ email, password }) => {
    try {
      const requiredRole = 'admin'
      const resp = await apiSignIn({ email, password, role: requiredRole });
      if (resp.data) {
        const { access_token, role, refresh_token } = resp.data.result.data;
        dispatch(onSignInSuccess({ access_token, refresh_token }));
        dispatch(
          setUser(
            //resp.data.user ||
            {
              avatar: "/img/avatars/thumb-2.jpg",
              // userName: "Anonymous",
              // authority: ["USER"],
              // email: "",
              userName: "Admin",
              email: "",
              // authority: ["admin", "user"],
              accountUserName: "admin",
              authority: [role],
            }
          )
        );
        localStorage.setItem('adminEmail', email)
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.clear()
    navigate(appConfig.unAuthenticatedEntryPath);

  };

  const signOut = async () => {
    const data = {
      refresh_token: refreshToken, 
      access_token: token,
    }
    try {
      await apiSignOut(data);
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
