const appConfig = {
  // apiPrefix: "https://backend.staging.ikrb.vvsg.flykube.agifly.cloud/",
  apiPrefix: "https://backend.production.ikrb.vvsgkube.agifly.cloud/",
  //apiPrefix: "https://backend.ww.develop.vvsgkube.agifly.cloud",
  authenticatedEntryPath: "/app/project/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
};

export default appConfig;
