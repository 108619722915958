import ApiService from "./ApiService";

export async function apiGetModuleByName(params) {
  return ApiService.fetchData({
    url: "/api/v1/app/info/module",
    method: "get",
    params,
  });
}

export async function apiGetModuleByLocation(params) {
  return ApiService.fetchData({
    url: "/api/v1/app/info/module/location",
    method: "get",
    params,
  });
}

export async function apiUpdateContentData(data) {
  return ApiService.fetchData({
    url: "/api/v1/app/info/module",
    method: "put",
    data: data.value,
    params: data.query_params,
  });
}

export async function apiAddImage(data) {
  return ApiService.fetchData({
    url: "/api/v1/app/info/module/image",
    method: "post",
    data,
    //params: data.query_params,
  });
}

export async function apiUpdateImage(data) {
  return ApiService.fetchData({
    url: "/api/v1/app/info/module/image",
    method: "put",
    //data: data.value,
    params: data,
  });
}
