import { createSlice } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "content/state",
  initialState: {
    drawerOpen: false,
    selectedModule: {},
  },
  reducers: {
    setSelectedModule: (state, action) => {
      state.selectedModule = action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false;
    },
  },
});

export const { setSelectedModule, setDrawerOpen, setDrawerClose } =
  stateSlice.actions;

export default stateSlice.reducer;
