import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetModuleByName,
  apiGetModuleByLocation,
  apiUpdateContentData,
} from "services/ContentServices";

/* axios.post(url, {}, { params: {key, checksum,},}).then((response) => {
    return success(response);
  })
  .catch((error) => {
    return fail(error);
  }); */

export const moduleByName = createAsyncThunk("moduleByName", async (params) => {
  const response = await apiGetModuleByName(params);
  return response.data.result;
});

export const modulesByLocation = createAsyncThunk(
  "modulesByLocation",
  async (params) => {
    const response = await apiGetModuleByLocation(params);
    //console.log("inside getusers call", response.data.result);
    return response.data.result;
  }
);

export const updateModule = createAsyncThunk("updateModule", async (data) => {
  const response = await apiUpdateContentData(data);
  return response.data.result;
});

const dataSlice = createSlice({
  name: "content/data",
  initialState: {
    loading: false,
    all_data: [],
  },
  reducers: {
    set_all_data: (state, action) => {
      state.all_data = action.payload;
    },
  },
  extraReducers: {
    [modulesByLocation.fulfilled]: (state, action) => {
      state.all_data = action.payload.data;
      state.loading = false;
    },
    [modulesByLocation.pending]: (state) => {
      state.loading = true;
    },
  },
});

export const { set_all_data } = dataSlice.actions;

export default dataSlice.reducer;
